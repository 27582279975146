//
import Vue from "vue";
import Resource from 'vue-resource';
Vue.use(Resource);
/*
  var path = require('path');
  console.log('path : ', path);
*/

const IS_LOCAL = process.env.NODE_ENV !== 'production';
var Locations_url = '../wam/config/Locations.txt';
var Locations_url = 'test.json';
/*
var fs = require('fs');
console.log(fs);
let x = fs.readFile(Locations_url, function(err, data) {
  res.writeHead(200, { 'Content-Type': 'text/html' });
  console.log(data);
  res.write(data);
  return res.end();
});
*/
/*
function readTextFile(file) {
  var rawFile = new XMLHttpRequest();
  rawFile.open("GET", file, false);
  rawFile.onreadystatechange = function() {
    if (rawFile.readyState === 4) {
      if (rawFile.status === 200 || rawFile.status == 0) {
        var allText = rawFile.responseText;
        console.log("allText : ", allText);
      }
    }
  }
  rawFile.send(null);
}
readTextFile(Locations_url)
*/
/*
Vue.http.get(Locations_url, { "test": 1 })
  .then(resp => {
    var data = resp.data;
    var Locations = JSON.parse(resp.data);
    console.log('Locations : ', Locations);
  }, resp => {
    console.log('error : ', resp);
  });
*/
const CLIENT_ID = 'wisdomhs';
const CLIENT_SECRET = 'wisdomera';
const GRANT_TYPE = 'password';

const API_BASE_URL = IS_LOCAL ?
  // 'http://localhost:5000/' :
  'https://waiwebcustomer-api.wisdomera.io/':
  'https://waiwebcustomer-api.wisdomera.io/';

const WDMR_URL = IS_LOCAL ?
  'https://wdmr.io/' :
  'https://wdmr.io/';

const WDMR_LINKS = {
  'record': WDMR_URL + '#/wdmr-record?',
  'show': WDMR_URL + '#/wdmr-show?',
  'wdmr_data_show': WDMR_URL + '#/wdmr-data-show?',
  'wdmr_data_record': WDMR_URL + '#/wdmr-data-record?'
};


const version = '2.0.1';

export {
  version,
  IS_LOCAL,
  API_BASE_URL,
  WDMR_LINKS,
  WDMR_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  GRANT_TYPE
};
